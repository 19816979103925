<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本配置" name="first">基本配置</el-tab-pane>
      <el-tab-pane label="国家/地区配置" name="second">国家/地区配置</el-tab-pane>
<!--      <el-tab-pane label="直销商配置" name="third">角色管理</el-tab-pane>-->
<!--      <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
  export default {
    name: 'BasicSettings',
    data() {
      return {
        activeName: 'first'
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    },
    created() {
    }

  }
</script>

<style scoped>

</style>
